// imports react and next
import dynamic from "next/dynamic";
import { Image as DatoImage } from "react-datocms";
import Link from "next/link";
import StructuredText from "@/common/UI/StructuredText";

// imports utilities
import { isEmpty } from "@/utils/functions";

// imports components
const Button = dynamic(() => import("@/common/UI/Button"));

const AbstractCard = (props) => {
  const {
    title,
    icon,
    abstract,
    btnUrl,
    btnLabel,
    bgImage,
    isBgImageGradient = true,
    bgColor = "#ffffff",
    hideIcon = false,
    isOpen = "_blank",
    isBigTitle = false,
  } = props;

  return (
    <div className="w-full h-full relative">
      {bgImage ? (
        <DatoImage
          data={{
            src: bgImage.responsiveImage?.src,
            alt: bgImage.alt || "card Bg Image",
            title: bgImage.title || "Card Title",
            placeholder: !isEmpty(bgImage.blurUpThumb) ? "blur" : "empty",
            blurDataURL: bgImage.blurUpThumb,
          }}
          layout="fill"
          className={`${isBgImageGradient ? "service-image-gradient" : ""}`}
          objectFit="cover"
        />
      ) : (
        <div
          style={{ backgroundColor: bgColor }}
          className={`absolute w-full h-full ${bgColor === "#ffffff" && "border border-[#D9D9D9]"}`}
        />
      )}
      <div className="z-[1] relative flex flex-col gap-4 text-center justify-center items-center sm:min-h-[304px] xxs:min-h-[329px] text-white p-6">
        {icon && (
          <DatoImage
            data={{
              src: icon?.url,
              height: icon?.height || 40,
              width: icon?.width || 40,
              alt: icon?.alt || "icon",
              title: icon?.title || "Icon Title",
              placeholder: !isEmpty(icon?.blurUpThumb) ? "blur" : "empty",
              blurDataURL: icon?.blurUpThumb,
            }}
          />
        )}
        <div>
          {isBigTitle ? (
            <h2
              className={`tracking-[0.5px] font-bold text-2xl mb-2 leading-7 font-futuraBold ${
                !bgImage && bgColor === "#ffffff" ? "text-primary" : "text-white"
              }`}
            >
              {title}
            </h2>
          ) : (
            <h3
              className={`tracking-[0.5px] font-bold text-xl mb-2 leading-7 font-futuraBold ${
                !bgImage && bgColor === "#ffffff" ? "text-primary" : "text-white"
              }`}
            >
              {title}
            </h3>
          )}
          {abstract && (
            <div
              className={`${
                !bgImage && bgColor === "#ffffff" ? "text-primary" : "text-white"
              } font-light text-base my-2 text-center`}
            >
              {typeof abstract === "string" ? (
                <p>{abstract}</p>
              ) : (
                <div className="structure-format">
                  <StructuredText data={abstract} />
                </div>
              )}
            </div>
          )}
        </div>
        {btnUrl && (
          <Link href={`${btnUrl}`} rel="noreferrer" target={isOpen}>
            <Button
              className={`w-auto max-h-[52px] btn ${
                !bgImage && bgColor === "#ffffff" ? "text-primary border-primary" : "text-white"
              }`}
            >
              {btnLabel && <span className="font-futuraBold">{btnLabel}</span>}
              {!hideIcon && (
                <div className={`${btnLabel && "ml-4"} py-auto flex`}>
                  <iconify-icon
                    icon="heroicons-outline:download"
                    width="21px"
                    height="20px"
                  ></iconify-icon>
                </div>
              )}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
export default AbstractCard;
